<script setup>
import SelectInput from "~/components/atoms/Inputs/SelectInput.vue";
import TextInput from "~/components/atoms/Inputs/TextInput.vue";
import FileInput from "~/components/atoms/Inputs/FileInput.vue";
import CheckIcon from "~/components/icons/CheckIcon.vue";
import ErrorIcon from "~/components/icons/ErrorIcon.vue";

const {t} = useI18n()

const props = defineProps({
  label: String,
  modelValue: String | Number,
  name: String,
  type: {
    type: String,
    default: 'text'
  },
  placeholder: String,
  disabled: {
    type: Boolean,
    default: false
  },
  options: {
    type: Array,
    default: () => []
  },
  validations: {
    type: Array,
    default: () => []
  },
  withValidationApprove: {
    type: Boolean,
    default: false
  }
})

const handleFileChangeAndEmit = (event) => {
  emit('filesChanged', event);

  handleFilesChanged(event);
};

const validations = {
  required: (noMessage = false) => {
    if (props.type === 'number') {
      const success = parseFloat(value.value) > 0
      if (!success && !noMessage) {
        errors.value.push(t('form.errors.required'))
      }

      return success
    } else if (props.type === 'file') {
      const success = files.value.length > 0
      if (!success && !noMessage) {
        errors.value.push(t('form.errors.required'))
      }

      return success
    } else {
      const success = value.value?.trim().length > 0

      if (!success && !noMessage) {
        errors.value.push(t('form.errors.required'))
      }

      return success
    }
  },
  email: (noMessage = false) => {
    const pattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
    const success = pattern.test(value.value?.trim())

    if (!success && !noMessage) {
      errors.value.push(t('form.errors.email'))
    }

    return success
  },
  order_number: (noMessage = false) => {
    const patterns = [/^[A-Za-z]{2}-\d{6}(-EXT)?$/i, /^[A-Za-z]{2}-\d{5}(-EXT)?$/i];
    const success = patterns.some(pattern => pattern.test(value.value));

    if (!success && !noMessage) {
      errors.value.push(t('form.errors.order_number'))
    }

    return success
  },
  file: (noMessage = false) => {
    const success = value.value && value.value.length > 0;
    if (!success && !noMessage) {
      errors.value.push(t('form.errors.order_number'))
    }

    return success;
  },
  'min': (noMessage, number) => {
    if (props.type === 'number') {
      const min = number
      const success = parseFloat(value.value) >= min

      if (!success && !noMessage) {
        errors.value.push(t('form.errors.min_number', {min}))
      }

      return success
    } else {
      const min = number
      const success = value.value.trim().length >= min

      if (!success && !noMessage) {
        errors.value.push(t('form.errors.min_number', {min}))
      }

      return success
    }
  }
}
const files = ref([]);

const handleFilesChanged = (event) => {
  files.value = event.files;
};

const emit = defineEmits(['update:modelValue', 'click', 'change', 'filesChanged'])

const value = ref(props.modelValue);

const {label, name} = props;

const errors = ref([]);

const active = ref(false);

const validate = () => {
  errors.value = []
  return props.validations.every(validation => {
    const [type, value] = validation.split(':')
    return validations[type](false, value)
  })
}

const checkValidation = () => {
  return props.validations.every(validation => {
    const [type, value] = validation.split(':')
    return validations[type](true, value)
  })
}

const updateValue = (event) => {
  const target = event.target;
  emit('update:modelValue', target.value);
};

watch(() => value.value, () => {
  updateValue({
    target: {value: value.value}
  })

  errors.value = []
});

let registerValidator;
let unregisterValidator;

if (process.client) {
  registerValidator = inject('registerValidator', null);
  unregisterValidator = inject('unregisterValidator', null);

  onBeforeMount(() => {
    if (registerValidator) {
      registerValidator(validate);
    }
  });

  onUnmounted(() => {
    if (unregisterValidator) {
      unregisterValidator(validate);
    }
  });
}
</script>

<template>
  <div>
    <div class="input-group" @click="$emit('click')"
         :class="[{active: active || Number.isFinite(value) || value?.length, disabled:props.disabled},{error:errors.length},type]">
      <p><span>{{ label }}</span></p>

      <div class="text-input" style="position: static;">
        <template v-if="type==='select'">
          <SelectInput @change="$emit('change',$event)" :disabled="disabled" :name="name" v-model="value"
                       :options="options"/>
        </template>
        <template v-if="type==='file'">
          <FileInput @filesChanged="handleFileChangeAndEmit" :disabled="disabled" :name="name" v-model="value"
                     :options="options"/>
        </template>
        <template v-if="['text','number','email','textarea','password','order_number'].includes(type)">
          <TextInput min="1" :disabled="disabled" :name="name" v-model="value" @focusout="active=false"
                     @change="$emit('change',$event)"
                     @focusin="active=true"
                     :type="type"/>
          <div class="validated" v-if="withValidationApprove && checkValidation()">
            <CheckIcon/>
          </div>
        </template>
      </div>
      <div class="error-icon" v-if="errors.length">
        <ErrorIcon/>
      </div>
    </div>
    <div v-if="errors.length" class="error-message">
      <p v-for="error in errors" :key="error">{{ error }}</p>
    </div>
  </div>
</template>

<style scoped lang="scss">
.error-icon {
  position: absolute;
  right: 20px;
  width: 12px;
  height: 12px;
  stroke: $red;
}

.validated {
  position: absolute;
  width: 20px;
  height: 15px;
  right: 8px;
  top: 21px;

  svg {
    stroke: $green;
  }
}

.input-group {
  color: $light-black;
  font-size: 13px;
  border: 1px solid $border-grey;
  padding: 10px 14px;
  position: relative;
  min-height: 57px;
  max-height: 57px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: $white;
  border-radius: 5px;

  &.textarea {
    min-height: 150px;
    overflow-y: hidden;

    textarea {
      min-height: 100px;
    }
  }

  &.file {
    border: 0;
    background: transparent;
    height: unset;
    min-height: unset;
    max-height: unset;
    color: $dark-black;
    padding-left: 20px;
  }

  &.select {
    cursor: pointer;

    input {
      cursor: pointer;
      pointer-events: none;
      color: black;
    }
  }

  p {
    height: 21px;
    margin-top: 8px;
    pointer-events: none;
    text-align: left;

    span {
      position: absolute;
      transform: translateY(7px);
      z-index: 9;
      font-size: 14px;
      transition: 0.4s ease-in-out;
    }
  }

  &.textarea {
    p {
      margin-top: 0;
    }
  }

  &.file {
    p {
      margin-top: 0;
      position: absolute;
      left: 20px;
      right: 0;
      top: -7px;

      span {
        text-decoration: underline;
      }
    }
  }

  &:deep(input) {
    height: 40px;
    padding-top: 19px;
    padding-left: 0;
    padding-bottom: 15px;
    color: black;
  }

  &:not(.select) {
    p {
      cursor: text;
    }
  }

  &.error {
    border-color: $red;

    p {
      span {
        color: $red;
      }
    }
  }

  &.active {
    p {
      span {
        transform: translateY(0);
        font-size: 12px;
      }
    }
  }
}

.error-message {
  color: $red;
  margin-top: 3px;
  font-size: 12px;

  p {
    margin: 0;
  }
}
</style>
