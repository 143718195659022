<script setup>
import HiddenInput from "~/components/atoms/HiddenInput.vue";
import ExpandIcon from "~/components/icons/ExpandIcon.vue";

const emit = defineEmits(['update:modelValue', 'focusout', 'focusin', 'change'])

const props = defineProps({
  modelValue: String | Number,
  name: String,
  disabled: {
    type: Boolean,
    default: false
  },
  options: Array,
})

const selectActive = ref(false);
const value = ref(props.modelValue)
const prefix = ref(props.options.find(option => option.key == value.value)?.prefix)

watch(() => value.value, (val) => {
  emit('update:modelValue', val)
  emit('change', val)
})

</script>

<template>
  <div @click="selectActive=!selectActive" class="row full-width select-container"
       :class="{active:!disabled && selectActive}">
    <HiddenInput hidden :name="name" v-model="value"/>

    <div class="row full-width g-8">
      <div v-if="prefix" class="prefix" v-html="prefix"></div>

      <HiddenInput disabled :value="options.find(option => option.key == value)?.value"/>
    </div>

    <ExpandIcon/>
  </div>

  <ul class="options full-width" v-if="!disabled && options.length && selectActive">
    <template v-for="option in options">
      <li class="option row full-width g-8" @click="value=option.key; selectActive=false; prefix=option.prefix;">
        <div class="no-flex" v-if="option.prefix" v-html="option.prefix"></div>
        {{ option.value }}
      </li>
    </template>
  </ul>
</template>

<style scoped lang="scss">

.options {
  position: absolute;
  width: 100%;
  list-style-type: none;
  background: $white;
  color: $dark-black;
  left: 0;
  top: 41px;
  z-index: 99;
  border: 1px solid $border-grey;
  padding: 0 15px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, .16);
  max-height: 200px;
  overflow: auto;

  .option {
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid $border-grey;
    cursor: pointer;
    font-weight: 500;
    display: flex;

    &:hover {
      background: $light-grey;
      margin-left: -15px;
      margin-right: -15px;
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}

.prefix {
  transform: translate(0px, 4px);
}

input {
  pointer-events: none;
}

svg {
  margin-top: -5px;
  transform: rotate(90deg);
}

.select-container.active {
  svg {
    transform: rotate(-90deg);
  }
}
</style>
